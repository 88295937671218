<template>
  <table
    v-if="qnaList"
    class="ListTable table-fixed w-full text-center text-sm mt-6"
  >
    <thead class="bg-gray-200">
      <tr class="h-10">
        <th class="w-10 border-t border-black border-b text-sm">LOT#</th>
        <th class="w-20 border-t border-black border-b text-sm">상품</th>
        <th class="w-30 border-t border-black border-b text-sm">내용</th>
        <th class="w-20 border-t border-black border-b text-sm">사용자아이디</th>
        <th class="w-10 border-t border-black border-b text-sm">회원명</th>
        <th class="w-14 border-t border-black border-b text-sm">회원휴대폰</th>
        <th class="w-20 border-t border-black border-b text-sm">파트너</th>
        <th class="w-14 border-t border-black border-b text-sm">updated</th>
      </tr>
    </thead>
    <tbody>
      <tr
        class="border-b border-grey-300 text-center cursor-pointer text-xs"
        v-for="(item, index) in qnaList"
        @click="handleClickRow(item)"
        :key="index"
      >
        <!-- LOT -->
        <td>
          <div>{{ item.lot }}</div>
        </td>

        <!-- 상품 -->
        <td class="text-left">
          <div>{{ item.artistName ? item.artistName : item.brandName }}</div>
          <div>{{ item.title || '-'}}</div>
        </td>

        <!-- 내용-->
        <td class="text-left">
          {{ item.memo || '-' }}
        </td>

        <!-- 사용자아이디 -->
        <td>
          {{ item.email }}
        </td>

        <!-- 회원명-->
        <td>
          {{ item.name || '-'}}
        </td>

        <!-- 회원휴대폰 -->
        <td>
          {{ item.mobile || '-'}}
        </td>

        <!-- 파트너 -->
        <td>
          {{ item.companyName || '-'}}
        </td>

        <!-- updated -->
        <td>
          {{ format(new Date(item.updatedAt), 'yyyy/MM/dd HH:mm') || '-'}}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent, onUpdated, PropType, ref } from 'vue';
import { format } from 'date-fns';
import router from '@/router';
import useCategoryOptions from '@/mixins/useCategoryOptions';

export default defineComponent({
  name: 'ListTable',
  components: {
  },
  props: {
    qnaList: Object as PropType<any>,
  },
  setup: function (props, { emit }) {
    const { categoryDepth1Obj } = useCategoryOptions();

    onUpdated(() => {
      console.log('qnaList', props.qnaList);
    });

    const handleClickRow = (item) => {
      // 상세로 이동
      router.push({
        path: '/app/qna/detail',
        query: {
          memberId: item.memberId,
          productId: item.productId,
        },
      });
    };


    return {
      format,
      handleClickRow,
      categoryDepth1Obj,
    };
  },
});
</script>

<style lang="scss" scoped>
.ListTable {
  tbody {
    tr {
    }
  }

  td {
    padding: 8px 10px;
  }
}
</style>
