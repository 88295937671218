<template>
  <form @submit.prevent="onClickSearch">
    <div class="flex w-full mt-6">
      <input
          type="text"
          class="
          Filter__textInput
          border border-black
          flex-1
          h-10
          py-3
          px-10
          text-xs
        "
          placeholder="상품, 문의내용, 파트너, 회원명, 휴대폰 번호로 검색"
          :value="params.keyword"
          @change="(e) => updateParams('keyword', e.target.value)"
      />
      <button class="w-30 h-10 ml-4 bg-black text-white text-xs" type="submit">
        검색
      </button>
    </div>
  </form>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { QnaListParams } from './index.vue';

export default defineComponent({
  name: 'ListFilter',
  components: {
  },
  props: {
    params: {
      type: Object as PropType<QnaListParams>,
      required: true,
    },
    handleChangeFilterParams: { type: Function, required: true },
    onClickSearch: { type: Function, required: true },
  },
  methods: {},
  setup(props) {
    const updateParams = (key: string, value: any) => {
      console.log('updateParams', key, value);
      props.handleChangeFilterParams(key, value);
    };

    return {
      updateParams,
    };
  },
});
</script>

<style lang="scss" scoped>
.ListFilter {
  display: flex;
  align-items: flex-end;
}

.FilterItem {
  width: 150px;
  display: inline-flex;
  flex-direction: column;

  & + & {
    margin-left: 6px;
  }

  & > label {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 8px;
  }

  & > input {
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    background: #ffffff;
    border: 1px solid #dddddd;
    padding: 0 16px;
    height: 40px;
    background: url('../../../assets/ico_selectbar_arrow.png') no-repeat;
    background-size: 10px;
    background-position: calc(100% - 14px) center;

    &.isNotSelected {
      color: #999;
    }
  }

  & > input {
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      background: transparent;
      bottom: 0;
      color: transparent;
      cursor: pointer;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  & > select {
    &.isNotSelected {
      color: #999;
    }
  }
}

.Filter__applyButton {
  width: 110px;
  text-align: left;
  margin-left: 6px;
  font-size: 14px;
  height: 40px;
  padding-left: 44px;
  background-size: 28px;
  background-image: url('../../../assets/ico_filter_default.svg');
  background-repeat: no-repeat;
  background-position: 10px center;
  box-sizing: border-box;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid #ff6363;
    background-image: url('../../../assets/ico_filter_hover.svg');
  }

  &.isApplied {
    background-color: #ff6363;
    background-image: url('../../../assets/ico_filter_applied.svg');
    color: #fff;
  }
}

.Filter__textInput {
  background-image: url('../../../assets/ico_Search.svg');
  background-repeat: no-repeat;
  background-position: 14px center;
  &::placeholder {
    color: #888;
  }
}
</style>
