
import { defineComponent, ref, onMounted, watch, reactive, toRef } from 'vue';
import { useRoute } from 'vue-router';
import Container from '@/components/Container.vue';
import Pagination from '@/components/Pagination/index.vue';
import router from '@/router/index';
import ListFilter from './ListFilter.vue';
import ListTable from './ListTable.vue';
import partnerAPI from '@/service/partnerAPI';

export type QnaListParams = {
  keyword?: string;
  page: number;
  pageSize: number;
};

export default defineComponent({
  name: 'QnaList',
  components: {
    Container,
    ListFilter,
    ListTable,
    Pagination,
  },
  props: {},
  setup() {
    const route = useRoute();
    const query = route.query;

    const filterParams = reactive<QnaListParams>({
      keyword: (query.keyword as string) || '',
      page: Number(query.page) || 0,
      pageSize: Number(query.pageSize) || 10,
    });

    const qnaList = ref({
      success: true,
      data: [],
      message: '',
      total: 0,
    });


    const handleChangeFilterParams = (key: string, value: any) => {
      filterParams[key] = value;
    };

    const handleChangePage = (page: number) => {
      filterParams.page = page - 1;
      executeSearch();
    };
    const handleChangePageSize = (pageSize: number) => {
      filterParams.page = 0;
      filterParams.pageSize = pageSize;
      executeSearch();
    };

    /**
     * 검색 실행
     */
    const executeSearch = () => {
      router.push({
        path: window.location.pathname,
        query: {
          keyword: filterParams.keyword || '',
          page: filterParams.page || 0,
          pageSize: filterParams.pageSize || 10,
        },
      });
    };

    const getAppliedFilterParams = () => {
      return {
        keyword: filterParams.keyword,
        page: filterParams.page,
        pageSize: filterParams.pageSize,
      };
    }

    const fetchQnaList = async () => {
      console.group('fetchQnaList');
      console.log('finalFilterParams', getAppliedFilterParams());
      console.groupEnd();

      try {
        const { data } = await partnerAPI.adminQna.adminQnaList(
            getAppliedFilterParams()
        );
        qnaList.value = data as any;
      } catch ({ response }) {
        console.error(response.data);
        alert(response.data.message);
      }
    };

    onMounted(fetchQnaList);

    watch([filterParams], (newFilter, newIsFilterOn) => {
      console.log('watch filter', newFilter, newIsFilterOn);
      fetchQnaList();
    });

    return {
      filterParams,
      qnaList,
      handleChangePage,
      handleChangePageSize,
      executeSearch,
      handleChangeFilterParams,
      fetchQnaList,
    };
  },
});
