
import { defineComponent, onUpdated, PropType, ref } from 'vue';
import { format } from 'date-fns';
import router from '@/router';
import useCategoryOptions from '@/mixins/useCategoryOptions';

export default defineComponent({
  name: 'ListTable',
  components: {
  },
  props: {
    qnaList: Object as PropType<any>,
  },
  setup: function (props, { emit }) {
    const { categoryDepth1Obj } = useCategoryOptions();

    onUpdated(() => {
      console.log('qnaList', props.qnaList);
    });

    const handleClickRow = (item) => {
      // 상세로 이동
      router.push({
        path: '/app/qna/detail',
        query: {
          memberId: item.memberId,
          productId: item.productId,
        },
      });
    };


    return {
      format,
      handleClickRow,
      categoryDepth1Obj,
    };
  },
});
